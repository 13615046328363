import React from 'react';
import CustomizedButton from '@/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button';
import buttonAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentButtonAnimationStyle.module.scss';
import { twMerge } from 'tailwind-merge';
import { getButtonVariantsData } from '../../Carousel/utils';
import { DownArrowIcon } from '../../CardFrameCollection/components/svg';
import { BUTTON_TYPE } from '@/components/WidgetMaker/utils/buttonConstant';
import { getRoute } from 'src/utils/routes';
import { useRouter } from 'next/router';
import { useSSRSelector } from 'src/redux';

export default function ViewMoreButton({
  listPresentationConfig,
  viewAllItemsConfig,
  setViewAllItemsConfig,
  cardFrameConfig,
  isVisible = false,
  selectedListParent = null,
  onCollectionClick,
}) {
  const router = useRouter();
  const { store } = useSSRSelector((state) => ({
    store: state.storeReducer.store,
  }));
  const forFeaturedProducts = listPresentationConfig?.contentItems?.type
    ?.toLocaleLowerCase()
    ?.includes('feature');

  const isBlogPage = listPresentationConfig?.type === 'blog';

  if (!isVisible) {
    return null;
  }

  return listPresentationConfig?.viewAllItemsButtonConfig?.map((buttonData, btnIndex) => {
    const buttonVariant = getButtonVariantsData(buttonData);
    const selectedButtonType = buttonVariant[buttonData.type];
    return buttonData.isShow ? (
      <CustomizedButton
        key={btnIndex}
        size={cardFrameConfig?.contentConfig?.textConfig?.textSize}
        btnType={buttonData.type}
        buttonData={buttonData}
        selectedButtonData={selectedButtonType}
        variant={selectedButtonType.variant}
        imageDirection={!isBlogPage ? 'right' : 'left'}
        image={
          !forFeaturedProducts &&
          !isBlogPage && (
            <DownArrowIcon
              arrowColor={buttonData?.btnStyleConfig?.textColor}
              height="18px"
              width="18px"
              className={twMerge(
                'tw-relative',
                viewAllItemsConfig?.areAllItemsVisible ? 'tw-rotate-180' : ''
              )}
            />
          )
        }
        onClick={(e) => {
          if (forFeaturedProducts && selectedListParent) {
            const forCollection =
              listPresentationConfig?.contentItems?.type === 'featuredCollection';
            onCollectionClick(
              forCollection ? 'collection' : 'category',
              selectedListParent.data,
              e,
              false
            );
          } else if (isBlogPage) {
            router.push(getRoute(`/p/blog`, store?.store_info?.domain));
          } else
            setViewAllItemsConfig((data) => ({
              ...data,
              areAllItemsVisible: !data.areAllItemsVisible,
            }));
        }}
        className={twMerge(
          'tw-pointer-events-auto !tw-mt-[36px] !tw-max-w-[100%] !tw-whitespace-nowrap !tw-px-[24px] !tw-py-[12px] !tw-text-[14px] !tw-font-normal !tw-leading-[20px]',
          !buttonData?.buttonAnimationConfig?.classKey
            ? '[&_p]:before:!tw-hidden'
            : buttonAnimationStyle[buttonData?.buttonAnimationConfig?.classKey],
          buttonData.type === BUTTON_TYPE.SOLID ? ' !tw-border-none ' : ''
        )}
      >
        {forFeaturedProducts || isBlogPage
          ? buttonData?.text
          : !viewAllItemsConfig?.areAllItemsVisible
            ? buttonData?.text
            : 'Show less'}
      </CustomizedButton>
    ) : null;
  });
}
